@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-7xl lg:text-9xl font-extrabold;
  }

  h2 {
    @apply text-5xl lg:text-7xl;
  }

  h3 {
    @apply text-3xl lg:text-5xl;
  }

  h4 {
    @apply text-xl lg:text-3xl;
  }

  h5 {
    @apply text-lg lg:text-2xl;
  }
  
  h6 {
    @apply text-lg;
  }

  p {
    @apply text-base lg:text-xl;
  }

  a {
    @apply text-sky-600 hover:underline underline-offset-[0.2em] cursor-pointer;
  }
}

@layer utilities {
  .flex-center {
    @apply flex justify-center items-center;
  }

}

@layer components {
  .navitem {
    @apply w-full h-12 rounded-[1.5rem] bg-black/80 flex items-center cursor-pointer;
  }


  .navitem > a {
    @apply text-white text-xl flex items-center z-[52] h-full pl-4 pr-2;
  }

  .navitem.active {
  }

  .navbar:hover .navitem {
  }

  .navbar:hover .navitem .remainingletter {
    @apply opacity-100
  }

  .navbar a:hover {
    @apply no-underline
  }

  .indicator {
    @apply bg-blue-700 absolute w-[calc(100%-0.5rem)] h-[3rem] rounded-r-[1.5rem] transition-all duration-300 ease-in-out z-[51];
  }
}

.projecttitle {
  text-shadow: 0.08em 0.08em 10px rgba(0, 0, 0, 0.498);
}

.handwritten {
  font-family: 'Shadows Into Light', cursive;
}

.card {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

body {
  margin: 0;
  font-family: Mandali;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
